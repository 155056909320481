export const MOODLE_BACKEND_ROUTES = {
  GET_ELECTIVE_PATH: 'block_programs_get_program_elective',
  POST_ELECTIVE_PATH: 'block_programs_user_elective_selection',
  GET_FEEDBACK_WIDGET_FEEDBACKS: 'block_feedback_widget_user_latest_feedback',
  GET_FEEDBACK_WIDGET_QUESTIONS: 'local_mobile_get_feedback_questions',
  POST_FEEDBACK_WIDGET: 'mod_feedback_process_page',
  GET_PROGRAM_DETAILS: 'block_programs_get_learningpath_about',
  GET_PROGRAMS: 'block_programs_get_learningpath_courses',
  GET_PROGRAMS_SECTION: 'block_programs_get_course_sections',
  GET_PROGRAMS_SECTION_ACTIVITY: 'block_programs_get_sections_activities',
  GET_RECENT_ACTIVITY: 'block_programs_get_course_recentactivities',
  GET_PROGRAM_SEARCH: 'local_program_search_get_program_search',
  GET_POLICY_DETAILS: 'local_consent_form_get_program_consent_content',
  POST_POLICY_DETAILS: 'local_consent_response',
  GET_ALL_NOTIFICATION: 'local_apis_get_notifications',
  MARK_ALL_READ: 'core_message_mark_all_notifications_as_read',
  // GET_ALL_NOTIFICATION: 'message_popup_get_popup_notifications',
  POST_NEW_FEATURE_NOTIFICATION:
    'local_feature_alert_feature_notification_view',
  POST_NEW_FEATURE_READ_NOTIFICATION: 'core_message_mark_notification_read',
  GET_RESOURCE_TAGS: 'local_learning_hub_get_user_resourcetags',
  GET_RESOURCE_DATA: 'local_learning_hub_get_learninghub_resources',
  GET_SINGLE_RESOURCE_DATA: 'local_learning_hub_get_resource_info',
  POST_BOOKAMRK_ARTICAL: 'local_learning_hub_save_user_resource_status',
  MARK_ARTICAL_READ: 'local_learning_hub_save_user_resource_status',
  GET_MEETING_DATA: 'mod_zoom_get_class_details',
  MARK_RECENT_ACTIVITY: 'local_apis_user_activity_view',
  GET_QUIZ_DETAILS: 'local_apis_get_quiz_details_user',
  START_QUIZ_GET_QUESTIONS: 'local_apis_get_question_with_start_attempt',
  POST_QUESTION_ANSWER: 'mod_quiz_process_attempt',
  BOOKMARK_QUIZ_QUESTION: 'local_apis_save_flag_questions',
  GET_QUESTION_REVIEW: 'local_apis_get_user_attempt_review',
  GET_MY_REFERRAL: 'block_get_refrals',
  POST_REFERRAL: 'block_add_refree',
  GET_REFER_PROGRAM: 'block_get_program_list',
  GET_CALENDAR_EVENTS: 'local_apis_get_user_events',
  GET_LIVE_CLASS: 'local_apis_get_ongoing_classes',
  GET_REMINDER_CLASS: 'local_apis_get_reminders',
  GET_CLASSES: 'local_apis_get_program_live_classes',
  GET_QUIZZES: 'local_apis_get_quiz_content',
  GET_ASSIGNMENT: 'local_apis_get_assignment_content',
  GET_RECENT_PROGRAM_COURSES: 'block_courses_get_recent_programcourses',
  GET_SECTION_DETAILS: 'local_apis_get_module_section',
  GET_PAGE_ACTIVITY: 'local_apis_get_page_actvity_content',
  GET_SESSION_RECORDING: 'tool_mobile_get_content',
  GET_DUPLICATE_SESSION_RECORDING: 'mod_zoom_get_class_recording',
  GET_ASSIGNMENT_STATUS: 'mod_assign_get_submission_status',
  SAVE_ASSIGNMENT_FILES: 'mod_assign_save_submission',
  GET_FEEDBACK_DETAILS: 'local_mobile_get_feedback_details',
  GET_FORUM_DISCUSSIONS: 'mod_forum_get_forum_discussions',
  GET_FORUM_THREADS: 'mod_forum_get_discussion_posts',
  ADD_DISCUSSION_IN_FORUM: 'mod_forum_add_discussion',
  SAVE_DRAFT_ASSIGNMENT: 'mod_assign_submit_for_grading',
  SET_VIDEO_AS_WATCHED: 'local_apis_update_user_videoprogress',
  GET_VIDEO_WATCHED_PROGRESS: 'tool_mobile_get_content'
};

export const FREE_COURSE_BACKEND_ROUTES = {
  GET_ENROLLED_COURSE_LIST: 'local_apis_get_enrolled_free_courses',
  GET_FREE_COURSE_LIST: 'local_apis_get_free_courses',
  GET_FREE_COURSE_MODULES: 'block_programs_get_course_sections',
  GET_MODULE_VIDEOS_LIST: 'block_programs_get_sections_activities',
  ENROLL_FREE_COURSE: 'local_apis_enroll_free_user',
  GET_ACTIVITY_DOWNLOAD_LINK: 'core_course_get_contents',
  FETCH_USER_CERTIFICATE: 'local_apis_get_user_certificate',
  UPDATE_USER_DETAILS: 'local_apis_create_update_enrol_user',
};

export const VASSIST_BACKEND_ROUTES = {
  GET_PROFILE_DATA: '/profile/phone',
  POST_PROFILE_DATA: '/profile',
  GET_LSQ_PROFILE_DETAILS: '/profile/lsq',
  GET_ZOOM_SIGNATURE: '/zoom/signature'
};
