import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

// Define a reducer registry object
const reducerRegistry = {};

// Function to add reducers to the registry
export const registerReducer = (name, reducer) => {
  reducerRegistry[name] = reducer;
};

// Lazy load and register each reducer
const loadReducers = async () => {
  // Import and register each slice's reducer one by one
  registerReducer(
    'dashboard',
    (await import('./redux/feature/dashboardSlice')).default
  );
  registerReducer(
    'drawer',
    (await import('./redux/feature/drawerSlice')).default
  );
  registerReducer(
    'pageActivity',
    (await import('./redux/feature/pageActivity')).default
  );
  registerReducer('user', (await import('./redux/feature/userSlice')).default);
  registerReducer('auth', (await import('./redux/feature/authSlice')).default);
  registerReducer(
    'learningPath',
    (await import('./redux/feature/learningPathSlice')).default
  );
  registerReducer(
    'monthlyCalendarDetail',
    (await import('./redux/feature/monthlyCalendarSlice')).default
  );
  registerReducer(
    'loginCred',
    (await import('./redux/feature/loginCredSlice')).default
  );
  registerReducer(
    'program',
    (await import('./redux/feature/programSlice')).default
  );
  registerReducer(
    'learnerData',
    (await import('./redux/feature/learnerDataSlice')).default
  );
  registerReducer(
    'splash',
    (await import('./redux/feature/splashSlice')).default
  );
  registerReducer(
    'profile',
    (await import('./redux/feature/profileWidget')).default
  );
  registerReducer(
    'elective',
    (await import('./redux/feature/electiveSlice')).default
  );
  registerReducer(
    'programTimeline',
    (await import('./redux/feature/programTimeline')).default
  );
  registerReducer(
    'feedbackWidget',
    (await import('./redux/feature/feedbackWidgetSlice')).default
  );
  registerReducer(
    'featureLaunch',
    (await import('./redux/feature/featureLaunchPopupSlice')).default
  );
  registerReducer(
    'learningHub',
    (await import('./redux/feature/learningHub')).default
  );
  registerReducer(
    'courseActivity',
    (await import('./redux/feature/courseActivitySlice')).default
  );
  registerReducer(
    'learnerDataSliceNew',
    (await import('./redux/feature/learnerDataSliceNew')).default
  );
  registerReducer('zoom', (await import('./redux/feature/zoomSlice')).default);
  registerReducer(
    'quizActivity',
    (await import('./redux/feature/quizActivitySlice')).default
  );
  registerReducer(
    'refer',
    (await import('./redux/feature/referSlice')).default
  );
  registerReducer(
    'courses',
    (await import('./redux/feature/freeCourseSlice')).default
  );
  registerReducer(
    'freeCourseStatic',
    (await import('./redux/feature/freeCourseStateSlice')).default
  );
  registerReducer(
    'assignmentActivity',
    (await import('./redux/feature/assignmentActivitySlice')).default
  );
  registerReducer(
    'forumActivitySlice',
    (await import('./redux/feature/forumActivitySlice')).default
  );
  registerReducer(
    'paidActivity',
    (await import('./redux/feature/paidActivitiesSlice')).default
  );
  registerReducer(
    'progressSlice',
    (await import('./redux/feature/progressSlice')).default
  );
};

// Load reducers and configure the Redux store
const asyncConfigureStore = async () => {
  await loadReducers();
  const reducers = combineReducers(reducerRegistry);
  return configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV === 'development' ? true : false,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat()
  });
};

export default asyncConfigureStore;
