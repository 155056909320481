import { useNavigate } from 'react-router-dom';
import { backendApiUrl } from '../api/config';

function epochToDateConverter(timestamp) {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = getMonthName(date.getMonth());
  const day = date.getDate();
  const daySuffix = getDaySuffix(day);

  return [day, daySuffix, month, year];
}

const getUserImage = async (prospectId, token, userid) => {
  const apiPhotoUrl = `${backendApiUrl}?moodlewsrestformat=json&wstoken=${token}&wsfunction=block_profile_get_user_profiledata&userid=${userid}&prospectid=${prospectId}`;
  const responseProfile = await fetch(apiPhotoUrl);
  const apiDataProfile = await responseProfile.json();
  let dataProfileData = apiDataProfile[0];
  return dataProfileData.userimage;
};
const preventContextMenu = (event) => {
  event.preventDefault();
};
function epochCurrentDateandTime() {
  var captureDateandTime = Math.floor(Date.now() / 1000);
  return captureDateandTime;
}

function epochCurrentTime() {
  const currentTimeInEpoch = Math.floor(new Date().getTime() / 1000);
  return currentTimeInEpoch;
}

function epochToTimeConverter(timestamp) {
  const date = new Date(timestamp * 1000);
  const timeString = date.toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  return timeString;
}

function getMonthName(monthIndex) {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  return monthNames[monthIndex];
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return 'th';
  }

  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

const parseQueryParams = () => {
  const urlParams = new URLSearchParams(document.location.search);
  const queryParams = {};
  for (const [key, value] of urlParams.entries()) {
    queryParams[key] = value;
  }
  return queryParams;
};

const setQueryParams = (paramObject) => {
  const searchParameter = new URLSearchParams();
  for (let key in paramObject) {
    searchParameter.set(key, paramObject[key]);
  }

  const currentPath = window.location.pathname;
  history.pushState(null, '', currentPath + '?' + searchParameter.toString());
};
const useRouteUpdate = (params, location, navigate, reset = false) => {
  let searchParams = {};
  searchParams = new URLSearchParams(reset ? [] : location.search);
  for (let key in params) {
    const value = params[key];

    if (value !== undefined && value !== null && value !== '') {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
  }
  const newLocation = { ...location, search: searchParams.toString() };
  navigate(newLocation);
};

const removeQueryParams = (queryString) => {
  const urlParams = new URLSearchParams(document.location.search);
  urlParams.delete('logout');
};
function getBreakPoint(width) {
  const breakpoints = [
    1920, 1536, 1350, 1200, 1024, 991, 768, 601, 550, 425, 375, 350, 300
  ];

  let breakpointIndex = -1;
  for (let i = 0; i < breakpoints.length; i++) {
    if (width >= breakpoints[i]) {
      breakpointIndex = i;
      break;
    }
  }

  if (breakpointIndex === -1) {
    return '0';
  } else {
    return breakpointIndex.toString();
  }
}
const getwindowSizes = {
  larger_desktop: 1920,
  l_desktop: 1536,
  sm_desktop: 1350,
  m_desktop: 1200,
  s_desktop: 1024,
  s_tempdesktop: 1023,
  l_tablet: 991,
  m_tablet: 768,
  s_tablet: 550,
  l_mobile: 425,
  m_mobile: 375,
  s_mobile: 350,
  xs_mobile: 300
};
// Get answer satus
const getAnswerStatus = (val) => {
  const userResponse = JSON.parse(val?.userresponse || '{}');
  const questionData = JSON.parse(val?.questiondata || '{}');
  if (!val?.answered) {
    return false;
  }
  if (
    val?.type === 'truefalse' ||
    (Number(questionData?.single) && val?.type == 'multichoice')
  ) {
    if (
      userResponse?.answer?.length == 0 &&
      userResponse?.answer?.length !== undefined
    ) {
      return false;
    }

    if (!userResponse?.answer) {
      return false;
    }

    return userResponse?.answer !== '-1';
  } else if (val?.type === 'shortanswer' || val?.type === 'essay') {
    if (val?.type === 'essay') {
      // need to check for 2 file cases
      if (val?.responsefileareas?.length) {
        return true;
      }
    }
    if (
      userResponse?.answer?.length == 0 &&
      userResponse?.answer?.length !== undefined
    ) {
      return false;
    }
    return userResponse?.answer?.length;
  } else if (val?.type == 'multichoice') {
    return Boolean(
      Object.values(userResponse)?.filter((val) => Number(val))?.length
    );
  } else {
    return false;
  }
};

export {
  epochToDateConverter,
  epochToTimeConverter,
  getDaySuffix,
  getMonthName,
  epochCurrentDateandTime,
  preventContextMenu,
  parseQueryParams,
  removeQueryParams,
  setQueryParams,
  epochCurrentTime,
  useRouteUpdate,
  getwindowSizes,
  getAnswerStatus,
  getUserImage
};
